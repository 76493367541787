import React from "react"

import WOW from 'wowjs';
import { Helmet } from "react-helmet"
import { findDOMNode } from "react-dom";
import * as yup from 'yup';

import UserContext from "../userContext.js"

import Countdown from "react-countdown";

import Footer from "../components/footer.jsx";

import LogoSlider from "../components/logoSlider.jsx";

import { Link } from "gatsby";

import UserTopbar from "../components/userTopbar";

import ilustration4 from '../images/ilustration-4.png'

import card from '../images/settings-card.svg'
import mt2 from '../images/my_troop_2.png'
import mt3 from '../images/my_troop_3.png'

import d1 from '../images/desafios/2desafio.svg'
import d2 from '../images/desafios/3desafio.svg'
import d3 from '../images/desafios/4desafio.svg'
import d4 from '../images/desafios/5desafio.svg'
import d5 from '../images/desafios/6desafio.svg'
import d6 from '../images/desafios/7desafio.svg'
import d7 from '../images/desafios/8desafio.svg'
import d8 from '../images/desafios/9desafio.svg'
import d9 from '../images/desafios/10desafio.svg'
import d10 from '../images/desafios/11desafio.svg'
import d11 from '../images/desafios/11desafio.svg'
import d12 from '../images/desafios/12desafio.svg'


import ClosedSelectableList from "../components/ClosedselectableList/index.jsx";

import filter from '../images/filter_icon.png'


import '../scss/settings.scss'
import 'semantic-ui-css/semantic.min.css'
import Selectable from "../components/selectable/index.jsx";
import SwitchButton from "../components/switchButton/index.jsx";

export default class feed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: false,
    };
    this.references = {
      hero: React.createRef(),
      services: React.createRef(),
      numbers: React.createRef(),
      features: React.createRef(),
      logos: React.createRef(),
      contact: React.createRef(),
    }
  }

  componentDidMount() {
    this.setState({
      client: true,
    })
  }

  render() {
    return (
      <>
        {this.state.client &&
          <>
            <div className="settings" >
              <UserContext.Consumer>
                {userObject => (
                  <>
                    <UserTopbar user={userObject} profilePic={userObject?.userObject?.picture || null} />
                    <Helmet>
                    </Helmet>
                    <div className="wrapper app">

                    

                      <div className="linear--header">
                        <h3>
                        Configuración
                        </h3>
                        <p>
                        Aquí vas  a encontrar todos los ajustes de tu perfil y tu cuenta.
                        </p>
                        <div className="filtering">
                              <p onClick={() => this.setState({ view: 0 })} className={(this.state.view == 0) ? "link--video active" : "link--video"}>Perfil</p>
                              <p onClick={() => this.setState({ view: 1 })} className={(this.state.view == 1) ? "link--video active" : "link--video"}>Mis troops</p>
                              <p onClick={() => this.setState({ view: 2 })} className={(this.state.view == 2) ? "link--video active" : "link--video"}>Accesibilidad</p>
                              <p onClick={() => this.setState({ view: 3 })} className={(this.state.view == 3) ? "link--video active" : "link--video"}>Seguridad y privacidad</p>
                              <p onClick={() => this.setState({ view: 4 })} className={(this.state.view == 4) ? "link--video active" : "link--video"}>Conexiones</p>
                        </div>
                      </div>

                    <img src={card} />


                    </div>
                  </>
                )}
              </UserContext.Consumer>
            </div>
            <Footer />
          </>
        }
      </>
    );
  }
}
